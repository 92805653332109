import React from "react";
import { graphql } from "gatsby";

import SpaceAnim from "../components/commons/SpaceAnim";
import Page from "../components/commons/Page";
import Hero from "../components/commons/Hero";
import ScrollIndicator from "../components/commons/ScrollIndicator";
import BlocksRenderer from "../components/commons/BlocksRenderer";
import Notification from "../components/commons/Notification";
import Button from "../components/commons/Button";

import { linkResolver } from "../utils/links";

const IndexPage = ({ location, pageContext, data }) => {
  const { sanityHome } = data;
  const buttonProps =
    sanityHome.button[0] && linkResolver(sanityHome.button[0]);

  return (
    <Page>
      <Hero fullscreen>
        <Hero.Background>
          <SpaceAnim />
        </Hero.Background>
        <Hero.Content sx={{ pointerEvents: "none" }}>
          <Hero.Title animateText={sanityHome.title} />
          <Hero.Subtitle animate>
            {sanityHome.subtitle}
            {buttonProps && (
              <Button
                key={buttonProps.to || buttonProps.href}
                {...buttonProps}
                variant="small"
                sx={{ mt: [3, 4], pointerEvents: "all" }}
              />
            )}
          </Hero.Subtitle>
        </Hero.Content>
        <ScrollIndicator sx={{ position: "absolute", bottom: 4 }} />
      </Hero>
      {sanityHome.notificationTitle && (
        <Notification
          title={sanityHome.notificationTitle}
          description={sanityHome.notificationDescription}
          cta={sanityHome.notificationButton[0]}
        />
      )}
      <BlocksRenderer blocks={sanityHome.blocks} />
    </Page>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPage {
    sanityHome {
      title
      subtitle
      button {
        ...Link
      }
      notificationTitle
      notificationDescription
      notificationButton {
        ...Link
      }
      blocks {
        ...Blocks
      }
    }
  }
`;
