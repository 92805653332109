import React from "react";
import { keyframes } from "@emotion/core";

import Box from "../Box";
import Heading from "../Heading";
import Text from "../Text";
import Button from "../Button";

import { linkResolver } from "../../../utils/links";
import { ReactComponent as Close } from "../../../images/icons/close.svg";

const slideIn = keyframes`
  0% {
    transform: translateX(100%)
  }
  100% {
    transform: translateX(0%)
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0%)
  }
  100% {
    transform: translateX(100%)
  }
`;

export default function Notification({
  title,
  description,
  cta,
  sx,
  ...otherProps
}) {
  const [isOpen, setState] = React.useState(true);

  const handleCloseClick = () => {
    setState(false);
  };

  return (
    <Box
      sx={{
        overflow: "hidden",
        position: "absolute",
        right: 0,
        bottom: [5, 6],
        pointerEvents: isOpen ? "auto" : "none",
        zIndex: "notification",
      }}
    >
      <Box
        as="section"
        sx={{
          background: "white",
          width: ["auto", "400px"],
          p: 3,
          borderLeft: "5px solid",
          borderLeftColor: "primary",
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
          animation: isOpen
            ? `${slideIn} 400ms 1s both`
            : `${slideOut} 400ms both`,
        }}
        {...otherProps}
      >
        <Heading variant="h5">{title}</Heading>
        <Text as="p" sx={{ mt: 2, mb: 3, fontSize: "small" }}>
          {description}
        </Text>
        {cta && <Button variant="smallInverted" {...linkResolver(cta)} />}
        <Button
          variant="reset"
          sx={{ position: "absolute", top: 3, right: 3 }}
          onClick={handleCloseClick}
        >
          <Close />
        </Button>
      </Box>
    </Box>
  );
}
