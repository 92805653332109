import React from "react";

import Button from "../Button";

import { ReactComponent as DownArrow } from "../../../images/icons/down-arrow.svg";

export default function ScrollIndicator({ sx, ...otherProps }) {
  const onClick = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  return (
    <Button
      variant="reset"
      sx={{ color: "inverted", ...sx }}
      onClick={onClick}
      aria-label="Scroll Down"
    >
      <DownArrow width="18" height="18" aria-hidden />
    </Button>
  );
}
